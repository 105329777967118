// src/PricingCard.js

import { Card, CardContent, CardActions, Typography, Box } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";

const PricingCard = ({
  plan,
  docVolumeMonthly,
  docVolumeYearly,
  features,
  checked,
}) => {
  const getIndex = () => {
    if (checked) {
      return docVolumeYearly;
    } else {
      return docVolumeMonthly;
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxWidth: 350,
        boxShadow: "none",
        border: "1px solid var(--border)",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          component="div"
          textAlign={"center"}
          color={"var(--button)"}
          fontWeight={"500"}
          fontSize={"25px"}
        >
          {plan}
        </Typography>
        <Typography
          color="textSecondary"
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginBottom={5}
        >
          <span
            style={{
              fontWeight: "700",
              fontSize: "40px",
              color: "var(--text-hover)",
              lineHeight: "1.5",
            }}
          >
            <span>{getIndex()} </span>
          </span>
          <span
            style={{
              fontSize: "16px",
              lineHeight: "0.2",
            }}
          >
            Pages
          </span>
        </Typography>
        <ul style={{ padding: 0 }}>
          {features.map((feature, index) => (
            <li
              key={index}
              style={{
                listStyle: "none",
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              {plan === "Starter" &&
              (feature === "DocEditor" || feature === "DocPublisher") ? (
                <CancelIcon
                  sx={{ color: "red", marginRight: 1, width: "20px" }}
                />
              ) : (
                <CheckCircle
                  sx={{ color: "green", marginRight: 1, width: "20px" }}
                />
              )}

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  maxWidth: "250px",
                }}
              >
                {feature}
              </Typography>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardActions>
        <Box
          as={Link}
          to={`/contact-us?planId=${plan}&planType=${
            checked ? "annual" : "monthly"
          }`}
          sx={{
            textAlign: "center",
            marginX: "auto",
            backgroundColor: "var(--button)",
            color: "var(--white)",
            display: "inline-block",
            borderRadius: "3px",
            maxHeight: "38px",
            width: "100%",
            textTransform: "uppercase",
            padding: "10px 1rem",
            fontWeight: "400",
            transition: "all 0.3s",

            "&:hover": {
              backgroundColor: "var(--secondary)",
            },
          }}
        >
          Choose Plan
        </Box>

        {/* <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            marginX: "auto",
            backgroundColor:
              plan === "Teams" ? "var(--secondary)" : "var(--button-hover)",

            "&:hover": {
              backgroundColor:
                plan === "Teams" ? "var(--button-hover)" : "var(--button)",
            },
          }}
        >
          Choose Plan
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default PricingCard;
