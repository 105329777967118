import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { Link } from "react-scroll";
import FetchData from "../Fetch";
import { Link as rLink } from "react-router-dom";
import splitByCapital from "../WordSplitter";

function OurSolution() {
  const theme = useTheme();

  const apiUrl1 = process.env.REACT_APP_API_URL;
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/product-pages?populate=*";
  const { data } = FetchData({ url: apiUrl });

  const bigCard = (item, index) => {
    if (index === 1 || index === 3) {
      return (
        <Grid
          key={index}
          name={item?.attributes?.Title?.replace(/\s+/g, "")}
          id={item?.attributes?.Title?.replace(/\s+/g, "")}
          container
          sx={{
            backgroundColor: "var(--ourSol-card)",
            marginBottom: "2rem",
            display: "flex",
            border: "0.5px solid var(--border)",
            borderRadius: "24px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: "-5px",
              },
            }}
          >
            <Box
              component={"img"}
              className="ourSol__image1"
              src={`${apiUrl1}${item?.attributes?.Image?.data[0]?.attributes?.url}`}
              alt={item?.attributes?.Title}
              srcSet=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              paddingTop: "0 !important",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "rgba(23, 35, 61, 1)",
                  // lineHeight: "63.28px",
                  [theme.breakpoints.down("md")]: {
                    textAlign: "center",
                  },
                }}
              >
                <span
                  style={{
                    color: "var(--text-hover)",
                  }}
                >
                  {splitByCapital(item?.attributes?.Title)[0]}
                </span>
                <Box component={"span"} sx={{ color: "var(--secondary)" }}>
                  {splitByCapital(item?.attributes?.Title)[1]}
                </Box>
              </Typography>
              {/* <Typography
                sx={{
                  marginY: "1rem",
                  lineHeight: "28.13px",
                  fontSize: "20px",
                  color: "var(--text-gray)",
                  fontWeight: "700",
                }}
              >
                {item?.attributes?.Sub_Title}
              </Typography> */}
              <Box
                className="card__description"
                component={"div"}
                sx={{
                  lineHeight: "28.13px",
                  fontSize: "15px",
                  color: "var(---text-primary)",
                  fontWeight: "400",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.attributes?.Descriptions,
                  }}
                />
              </Box>
              {item?.attributes?.Title === "DocMigration" ? (
                <Typography
                  as={rLink}
                  to="/try-now"
                  sx={{
                    display: "inline-block",
                    fontWeight: "500",
                    paddingX: "0",
                    cursor: "pointer",
                    color: "var(--white)",
                    background: "var(--button)",
                    padding: "10px 20px",
                    borderRadius: "100px",

                    "&:hover": {
                      background: "var(--secondary)",
                    },
                  }}
                >
                  Try It Now
                </Typography>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          key={index}
          name={item?.attributes?.Title.replace(/\s+/g, "")}
          id={item?.attributes?.Title.replace(/\s+/g, "")}
          container
          sx={{
            backgroundColor: "var(--ourSol-card)",
            marginBottom: "2rem",
            display: "flex",
            border: "0.5px solid var(--border)",
            borderRadius: "24px",

            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              paddingTop: "0 !important",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "1rem" }}>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "rgba(23, 35, 61, 1)",
                  // lineHeight: "63.28px",
                  [theme.breakpoints.down("md")]: {
                    textAlign: "center",
                  },
                }}
              >
                <span
                  style={{
                    color: "var(--text-hover)",
                  }}
                >
                  {splitByCapital(item?.attributes?.Title)[0]}
                </span>
                <Box component={"span"} sx={{ color: "var(--secondary)" }}>
                  {splitByCapital(item?.attributes?.Title)[1]}
                </Box>
              </Typography>
              {/* <Typography
                sx={{
                  marginY: "1rem",
                  lineHeight: "28.13px",
                  fontSize: "20px",
                  color: "var(--text-gray)",
                  fontWeight: "700",
                }}
              >
                {item?.attributes?.Sub_Title}
              </Typography> */}
              <Box
                className="card__description"
                component={"div"}
                sx={{
                  lineHeight: "28.13px",
                  fontSize: "15px",
                  color: "var(--text-primary)",
                  fontWeight: "400",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.attributes?.Descriptions,
                  }}
                />
              </Box>
              {item?.attributes?.Title === "DocPublisher" ? (
                <Typography
                  as={rLink}
                  to="/try-now-publisher"
                  sx={{
                    display: "inline-block",
                    fontWeight: "500",
                    paddingX: "0",
                    cursor: "pointer",
                    color: "var(--white)",
                    background: "var(--button)",
                    padding: "10px 20px",
                    borderRadius: "100px",
                    "&:hover": {
                      background: "var(--secondary)",
                    },
                  }}
                >
                  Try It Now
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: "-5px",
              },
            }}
          >
            <Box
              component={"img"}
              className="ourSol__image2"
              src={`${apiUrl1}${item?.attributes?.Image.data[0]?.attributes?.url}`}
              alt={item?.attributes?.Title}
              srcSet=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const scrollToWithOffset = (id, offset) => () => {
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Our Solution Buttion and btn title
    const our__solution = document.querySelectorAll("#our__solution > div");

    const solButtons = document.querySelectorAll(".solButton");
    let prevHighlightedIndex = null;

    const handleScroll = () => {
      our__solution.forEach((element, index) => {
        if (element) {
          const rect = element.getBoundingClientRect();
          const threshold = 200;

          if (rect.top <= threshold && rect.top >= -element.clientHeight) {
            if (
              prevHighlightedIndex !== null &&
              prevHighlightedIndex !== index
            ) {
              solButtons.forEach((btn) => {
                if (
                  btn.getAttribute("data") ===
                  our__solution[prevHighlightedIndex].getAttribute("name")
                ) {
                  btn.classList.remove("buttonHighlight");
                }
              });
            }

            solButtons.forEach((btn) => {
              if (btn.getAttribute("data") === element.getAttribute("name")) {
                btn.classList.add("buttonHighlight");
              }
            });

            prevHighlightedIndex = index;
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      const stickyHeader = document.getElementById("sticky__header");

      if (stickyHeader) {
        const stickyHeaderPosition = stickyHeader.getBoundingClientRect().top;
        if (stickyHeaderPosition === 0) {
        } else {
          const aa = stickyHeader.querySelectorAll("A");
          aa.forEach((element) => {
            if (element.classList.contains("buttonHighlight")) {
              element.classList.remove("buttonHighlight");
            }
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box component="div" sx={{ paddingTop: "2rem" }}>
      <Typography
        align="center"
        sx={{
          fontSize: "42px",
          fontWeight: "700",
          color: "var(--text-primary)",
        }}
      >
        Our Solutions
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: "20px",
          fontWeight: "400",
          color: "var(--text-gray)",
          // paddingY: "1rem",
        }}
      >
        Unlock the full potential of your content conversion and migration with
        metR suite of solutions
      </Typography>
      <Box
        component={"div"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        top={"3rem"}
        position={"sticky"}
        id="sticky__header"
        sx={{
          paddingTop: "2rem",
          flexWrap: "wrap",
          background: "var(--white)",
          [theme.breakpoints.down("md")]: {
            position: "static",
          },
        }}
      >
        {data?.data?.map((item, index) => (
          <Link
            to={""}
            key={index}
            onClick={scrollToWithOffset(
              item?.attributes?.Title.replace(/\s+/g, ""),
              160
            )}
            smooth={true}
            data={item?.attributes?.Title?.replace(/\s+/g, "")}
            duration={500}
            className="solButton"
            style={{
              cursor: "pointer",
              fontSize: "18px",
              fontWeight: "400",
              border: "0.8px solid var(--border)",
              padding: "8px 1rem",
              borderRadius: "32px",
              color: "var(--button)",
              margin: "1rem",
              marginTop: "10px",
              marginBottom: "10px",
              minWidth: "120px",
              textAlign: "center",
            }}
          >
            {item?.attributes?.Title}
          </Link>
        ))}
      </Box>
      <Box id="our__solution" component={"div"} sx={{ paddingY: "2rem" }}>
        {data?.data?.map((item, index) => bigCard(item, index))}
      </Box>
    </Box>
  );
}

export default OurSolution;
