import { Box, Typography } from "@mui/material";
import React from "react";
import CustomContainer from "../components/CustomContainer";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const theme = useTheme();
  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "calc(100vh - 340.52px)",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomContainer>
        <Typography
          align="center"
          sx={{
            fontSize: {
              xs: "100px",
              sm: "150px",
              md: "200px",
              lg: "240px",
            },
            fontWeight: "300",
            lineHeight: "1",
            color: "var(--secondary)",
          }}
        >
          404
        </Typography>
        <Typography
          align="center"
          sx={{
            fontSize: "22px",
            color: "var(--text-gray)",
          }}
        >
          Oops! Something went wrong…
        </Typography>
        <Typography
          align="center"
          mt={3}
          sx={{
            fontSize: "18px",
            color: "var(--text-gray)",
          }}
        >
          Page not found. Please continue to our{" "}
          <Link
            style={{
              color: "var(--text-hover)",
              textDecoration: "underline",
            }}
            to="/"
          >
            home page
          </Link>
          .
        </Typography>
      </CustomContainer>
    </Box>
  );
};

export default PageNotFound;
