import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Container,
  Card,
} from "@mui/material";
import SliderItem from "./SliderItem";
import CustomContainer from "../CustomContainer";
import { debounce } from "lodash";

const ResponsiveModal = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [total, setTotal] = useState(0);

  const [values, setValues] = useState({
    userExp: 0,
    contentDisc: 0,
    efficiency: 0,
    analyticsInsights: 0,
    scalabilityFlexibility: 0,
    competitiveAdvantage: 0,
    costReduction: 0,
  });

  const handleChange = (name, newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const calculateTotal = useCallback(() => {
    const total =
      values.userExp * 0.075 +
      values.contentDisc * 0.15 +
      values.efficiency * 0.2 +
      values.analyticsInsights * 0.125 +
      values.scalabilityFlexibility * 0.15 +
      values.competitiveAdvantage * 0.075 +
      values.costReduction * 0.125;

    setTotal(total);
  }, [values]);

  useEffect(() => {
    const debouncedCalculateTotal = debounce(calculateTotal, 300);
    debouncedCalculateTotal();

    return () => {
      debouncedCalculateTotal.cancel();
    };
  }, [calculateTotal, values]);

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",
        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <Card
            sx={{
              maxWidth: 700,
              marginX: "auto",
              marginY: "3rem",
              padding: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></Box>
            <Typography
              id="modal-modal-title"
              textAlign="center"
              sx={{
                fontSize: isSmallScreen ? "24px" : "30px",
                fontWeight: "700",
                color: "var(--button)",
                mb: 2,
              }}
            >
              ROI Calculator
            </Typography>
            <Grid container spacing={4} mt={1}>
              <SliderItem
                label="Enhanced User Experience"
                name="userExp"
                value={values.userExp}
                handleChange={handleChange}
              />
              <SliderItem
                label="Content Discovery"
                name="contentDisc"
                value={values.contentDisc}
                handleChange={handleChange}
              />
              <SliderItem
                label="Efficiency"
                name="efficiency"
                value={values.efficiency}
                handleChange={handleChange}
              />
              <SliderItem
                label="Analytics Insights"
                name="analyticsInsights"
                value={values.analyticsInsights}
                handleChange={handleChange}
              />
              <SliderItem
                label="Scalability and Flexibility"
                name="scalabilityFlexibility"
                value={values.scalabilityFlexibility}
                handleChange={handleChange}
              />
              <SliderItem
                label="Competitive Advantage"
                name="competitiveAdvantage"
                value={values.competitiveAdvantage}
                handleChange={handleChange}
              />
              <SliderItem
                label="Cost Reduction"
                name="costReduction"
                value={values.costReduction}
                handleChange={handleChange}
              />
            </Grid>
            <Box
              sx={{
                marginTop: "2rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "var(--text2)",
                }}
              >
                ROI ={" "}
                <span
                  style={{
                    color: "var(--button)",
                  }}
                >
                  {total.toFixed(2)}
                </span>
              </Box>
            </Box>
          </Card>
        </Container>
      </CustomContainer>
    </Box>
  );
};

export default ResponsiveModal;
