import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FetchData from "./Fetch";

function Benefits() {
  const theme = useTheme();

  const apiUrl1 = process.env.REACT_APP_API_URL;
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/product-benefits?populate=*";
  const { data } = FetchData({ url: apiUrl });

  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller1, .scroller2");
    const addAnimation = () => {
      if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        scrollers.forEach((scroller) => {
          const scrollerInner = scroller.querySelector(".scroller__inner");

          const scrollerContent = Array.from(scrollerInner.children);

          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
          });
        });
      }
    };
    addAnimation();
  }, [data]);

  const halfLength = Math.ceil(data?.data.length / 2);
  const firstHalf = data?.data.slice(0, halfLength);
  const secondHalf = data?.data.slice(halfLength);

  return (
    <Box component="div" sx={{ paddingTop: "64px" }}>
      <Typography
        align="center"
        sx={{
          fontSize: "42px",
          fontWeight: "700",
          color: "var(--text-primary)",
        }}
      >
        Benefits
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: "20px",
          fontWeight: "400",
          color: "var(--text-gray)",
          paddingY: "1rem",
        }}
      >
        Having your unstructured content converted to DITA, brings you the
        following business benefits.
      </Typography>

      <Box component={"div"} sx={{ paddingY: "2rem" }}>
        <Grid
          container
          sx={{
            display: "flex",

            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              [theme.breakpoints.down("md")]: {
                marginBottom: "2rem",
              },
              [theme.breakpoints.up("md")]: {
                paddingRight: "2rem",
              },
            }}
          >
            <Box
              component="div"
              className="parentOfParent"
              sx={{
                // backgroundColor: "var(--card-bg-secondary)",
                background: "var(--button)",

                height: "100%",
                minHeight: "330px",
                // maxHeight: "500px",
                padding: "10px",
                overflow: "hidden",
                borderRadius: "3px",
              }}
            >
              <Box
                className="parentDiv"
                component={"div"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  height: "100%",
                }}
              >
                <div
                  className="scroller1"
                  data-animated="true"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ul
                      className="tag-list scroller__inner"
                      style={{ paddingLeft: "0" }}
                    >
                      {firstHalf?.map((item, index) => (
                        <Box
                          key={index}
                          component="div"
                          className="hello"
                          sx={{
                            background: "var(--white)",
                            width: "110px",
                            height: "110px",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="div"
                            sx={{
                              backgroundColor:
                                index === 1 || index === 3 || index === 5
                                  ? "var(--secondary)"
                                  : "var(--text-primary)",
                              padding: "10px",
                              borderRadius: "100%",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <img
                              src={`${apiUrl1}${item?.attributes?.Image?.data?.attributes?.url}`}
                              alt={item?.attributes?.Title}
                            />
                          </Box>
                        </Box>
                      ))}
                    </ul>
                  </Grid>
                </div>
                <div
                  className="scroller2"
                  data-animated="true"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={4}
                    data-direction="right"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ul className="tag-list scroller__inner">
                      {secondHalf?.map((item, index) => (
                        <Box
                          key={index}
                          component="div"
                          className="hello"
                          sx={{
                            background: "var(--white)",
                            width: "110px",
                            height: "110px",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="div"
                            sx={{
                              backgroundColor:
                                index === 0 || index === 2
                                  ? "var(--secondary)"
                                  : "var(--text-primary)",
                              padding: "10px",
                              borderRadius: "100%",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <img
                              src={`${apiUrl1}${item?.attributes?.Image?.data?.attributes?.url}`}
                              alt={item?.attributes?.Title}
                            />
                          </Box>
                        </Box>
                      ))}
                    </ul>
                  </Grid>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className="div00"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {firstHalf?.map((item, index) => (
              <Grid
                className="div1"
                item
                xs={12}
                key={index}
                sx={{
                  height: "100%",
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "space-between",
                  paddingX: "0",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "2rem",
                  },
                  [theme.breakpoints.up("md")]: {
                    paddingX: "1rem",
                  },
                  [theme.breakpoints.up("lg")]: {
                    paddingX: "0",
                  },
                }}
              >
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      backgroundColor:
                        index === 1 || index === 3 || index === 5
                          ? "var(--secondary)"
                          : "var(--text-primary)",
                      padding: "10px",
                      borderRadius: "100%",
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    <img
                      src={`${apiUrl1}${item?.attributes?.Image?.data?.attributes?.url}`}
                      alt={item?.attributes?.Title}
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box
                    component="div"
                    sx={{
                      paddingLeft: "1rem",
                    }}
                  >
                    <Typography
                      align="left"
                      sx={{
                        fontSize: "21px",
                        fontWeight: "600",
                        color: "var(--text-primary)",
                      }}
                    >
                      {item?.attributes?.Title}
                    </Typography>
                    <Box
                      component={"div"}
                      align="left"
                      sx={{
                        color: "var(--text-primary)",
                        marginBottom: "0",
                      }}
                    >
                      <div
                        className="benefits__para"
                        dangerouslySetInnerHTML={{
                          __html: item?.attributes?.Descriptions,
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className="div00"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // [theme.breakpoints.down("md")]: {
              //   marginTop: "1rem",
              // },
            }}
          >
            {secondHalf?.map((item, index) => (
              <Grid
                className="div1"
                item
                xs={12}
                key={index}
                sx={{
                  height: "100%",
                  display: "flex",

                  justifyContent: "space-between",
                  paddingX: "0",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "2rem",
                  },
                  [theme.breakpoints.up("md")]: {
                    paddingX: "1rem",
                  },
                  [theme.breakpoints.up("lg")]: {
                    paddingX: "0",
                  },
                }}
              >
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      backgroundColor:
                        index === 0 || index === 2
                          ? "var(--secondary)"
                          : "var(--text-primary)",
                      padding: "10px",
                      borderRadius: "100%",
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    <img
                      src={`${apiUrl1}${item?.attributes?.Image?.data?.attributes?.url}`}
                      alt={item?.attributes?.Title}
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box
                    component="div"
                    sx={{
                      paddingLeft: "1rem",
                    }}
                  >
                    <Typography
                      align="left"
                      sx={{
                        fontSize: "21px",
                        fontWeight: "600",
                        color: "var(--text-primary)",
                      }}
                    >
                      {item?.attributes?.Title}
                    </Typography>
                    <Box
                      paragraph="true"
                      align="left"
                      sx={{
                        color: "var(--text-primary)",
                        marginBottom: "0",
                      }}
                    >
                      <div
                        className="benefits__para"
                        dangerouslySetInnerHTML={{
                          __html: item?.attributes?.Descriptions,
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Benefits;
