const apiUrl = process.env.REACT_APP_API_URL_PREFLIGHT;

async function sendEmail({ mailTo, subject, mailBody, attachment }) {
  const formData = new FormData();
  formData.append("mailTo", mailTo);
  formData.append("subject", subject);
  formData.append("mailBody", mailBody);

  if (attachment) {
    formData.append("attachment", attachment);
  }

  try {
    const response = await fetch(`${apiUrl}/api/email-server`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (response.ok) {
      return { status: "success", message: result.message };
    } else {
      return { status: "error", message: result.message };
    }
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

module.exports = sendEmail;
