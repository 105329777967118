import React from "react";
import { Box, Stack, Container, ListItemIcon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CustomContainer from "../components/CustomContainer";
import logo from "../assets/logo.svg";

function Footer() {
  const theme = useTheme();
  return (
    <Box
      component={"footer"}
      position="static"
      sx={{
        backgroundColor: "#fff",

        boxShadow: "none",
      }}
    >
      <Box
        component={"div"}
        sx={{
          borderTop: "0.8px solid #17233D66",
          borderBottom: "0.4px solid #17233D66",
        }}
      >
        <CustomContainer>
          <Container>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingY: "1rem",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ color: "black" }}>
                <ListItemIcon>
                  <Link to={"/"}>
                    <img src={logo} alt="" srcSet="" />
                  </Link>
                </ListItemIcon>
              </Box>
              <Box
                component={"div"}
                gap={5}
                sx={{
                  display: "flex",
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                    gap: theme.spacing(2),
                    marginY: "1rem",
                  },
                }}
              >
                <Link
                  to="https://metapercept.com/aboutus/"
                  target="_blank"
                  className="footerLinks"
                  style={{
                    textDecoration: "none",
                    color: "var(--text-primary)",
                  }}
                >
                  About us
                </Link>
                <Link
                  className="footerLinks"
                  to="https://metapercept.com/privacy-policy/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "var(--text-primary)",
                  }}
                >
                  Privacy policy
                </Link>
                <Link
                  className="footerLinks"
                  to="https://metapercept.com/gdpr/termsandconditions/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "var(--text-primary)",
                  }}
                >
                  Terms of use
                </Link>
                <Link
                  className="footerLinks"
                  to="https://metapercept.com/cookie-policy/"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "var(--text-primary)",
                  }}
                >
                  Cookie preferences
                </Link>
              </Box>

              <Stack direction={"row"} spacing={2}>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/metapercepttechservices/"
                  style={{ textDecoration: "none", color: "var(--button)" }}
                >
                  <FacebookRoundedIcon />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/showcase/metrdocumentpublishingtool/about/"
                  style={{
                    textDecoration: "none",
                    color: "var(--button)",
                  }}
                >
                  <LinkedInIcon />
                </Link>
                <Link
                  target="_blank"
                  to="https://twitter.com/MetaPercept"
                  style={{
                    textDecoration: "none",
                    color: "var(--button)",
                  }}
                >
                  <XIcon
                    sx={{
                      width: "19px",
                    }}
                  />
                </Link>

                <Link
                  target="_blank"
                  to="https://blog.metapercept.com/"
                  style={{ textDecoration: "none", color: "var(--button)" }}
                >
                  <RssFeedIcon />
                </Link>
              </Stack>
            </Box>
          </Container>
        </CustomContainer>
      </Box>
      <CustomContainer>
        <Container>
          <Box
            component={"div"}
            sx={{
              paddingY: "10px",
            }}
          >
            <Typography
              align="center"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "var(--text-primary)",
              }}
            >
              Copyright © 2024{" "}
              <a
                className="footerLinks"
                target="_blank"
                rel="noreferrer noopener"
                href="https://metapercept.com/"
              >
                Metapercept Technology Services LLP
              </a>{" "}
              All Rights Reserved
            </Typography>
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default Footer;
