import React from "react";
import CustomContainer from "../components/CustomContainer";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TryNowBanner from "../components/TryNowBanner";

import Survey from "./Survey";

const TryNow = () => {
  const theme = useTheme();

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <TryNowBanner title="Survey" />
          <Survey />
        </Container>
      </CustomContainer>
    </Box>
  );
};

export default TryNow;
