import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import RequestDemo from "./RequestDemo";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import FetchData from "./Fetch";

function Banner() {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [currentData, setCurrentData] = useState(0);

  // const [images, setImages] = useState([]);
  // const [allData, setAllData] = useState([]);

  const API_URL =
    process.env.REACT_APP_API_URL + "/api/landing-page-sliders?populate=*";

  const BaseURL = process.env.REACT_APP_API_URL;

  const { data } = FetchData({ url: API_URL });

  let imagesList = data?.data?.map(
    (i) => i?.attributes?.banner?.data[0]?.attributes?.url
  );

  let DataList = data?.data?.map((i) => i?.attributes);

  const allData = useMemo(() => DataList, [DataList]);
  const images = useMemo(() => imagesList, [imagesList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images?.length - 1 ? 0 : prevImage + 1
      );
      setCurrentData((prevData) =>
        prevData === allData?.length - 1 ? 0 : prevData + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [allData, images]);

  return (
    <>
      <Box component={"div"} sx={{ paddingY: "2rem" }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Grid
            item
            md={6}
            style={{
              overflow: "hidden",
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                margin: "0 auto",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease-in-out",
                  transform: `translateX(-${currentData * 100}%)`,
                  height: "100%",
                }}
              >
                {allData?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{ width: "100%", height: "100%", flex: "0 0 auto" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "rgba(23, 35, 61, 1)",
                          fontSize: {
                            xs: "28px",
                            sm: "35px",
                            md: "38px",
                            lg: "40px",
                          },

                          lineHeight: {
                            xs: "40px",
                            sm: "55px",
                            md: "55px",
                            lg: "60px",
                          },
                        }}
                      >
                        {item?.Title}
                      </Typography>
                      <Box
                        sx={{
                          marginY: "2rem",
                          lineHeight: "28.13px",
                          fontSize: "20px",
                          color: "var(--text-gray)",
                          fontWeight: "400",
                          [theme.breakpoints.down("lg")]: {
                            fontSize: "16px",
                          },
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.Short_desc,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
              <Link to={"/request-demo"} className="requestButton">
                <RequestDemo fsize={"16px"} bgColor={"var(--secondary)"} />
              </Link>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              overflow: "hidden",
              position: "relative",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                margin: "0 auto",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease-in-out",
                  transform: `translateX(-${currentImage * 100}%)`,
                  height: "100%",
                }}
              >
                {images?.map((image, index) => (
                  <img
                    key={index}
                    src={BaseURL + image}
                    alt={`Slide ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      flex: "0 0 auto",
                    }}
                  />
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Banner;
