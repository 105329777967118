import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Calculator = () => {
  return (
    <Box component="div" sx={{ paddingTop: "64px" }}>
      <Typography
        align="center"
        sx={{
          fontSize: "42px",
          fontWeight: "700",
          color: "var(--text-primary)",
        }}
      >
        ROI Calculator
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: "20px",
          fontWeight: "400",
          color: "var(--text-gray)",
          paddingY: "1rem",
        }}
      >
        Calculate the return on investment using the business drivers.
      </Typography>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingY: "2rem",
        }}
      >
        <Link to="/roi-calculator">
          <Box
            sx={{
              color: "var(--white)",
              display: "inline-block",
              borderRadius: "100px",
              maxHeight: "38px",
              background: "var(--secondary)",
              padding: "10px 1rem",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "16px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
            }}
          >
            {" "}
            Calculate Now
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Calculator;
