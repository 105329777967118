// CustomThemeProvider.js
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const CustomContainer = ({ children }) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1450,
      },
    },
    container: {
      maxWidth: {
        lg: "1450px",
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomContainer;
