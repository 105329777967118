import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Stack,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Collapse,
  useScrollTrigger,
} from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import RequestDemo from "../components/RequestDemo";
import CustomContainer from "../components/CustomContainer";
import logo from "../assets/logo.svg";
import doc_manager from "../assets/doc_manager.svg";
import doc_migration from "../assets/doc_migration.svg";
import doc_publisher from "../assets/doc_publisher.svg";
import doc_editor from "../assets/doc_editor.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSub, setOpenSub] = useState(-1);
  const [hoveredMenu, setHoveredMenu] = useState(-1);
  const theme = useTheme();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { name: "Home", link: "/" },
    {
      name: "Solution",
      link: "/solutions",
      subCategories: [
        { name: "DocManager", link: "/DocManager", icon: doc_manager },
        { name: "DocEditor", link: "/DocEditor", icon: doc_editor },
        { name: "DocPublisher", link: "/DocPublisher", icon: doc_publisher },
        { name: "DocMigration", link: "/DocMigration", icon: doc_migration },
      ],
    },
    {
      name: "Buy",
      link: "/buy",
    },
    {
      name: "Resources",
      link: "/resources/conversion-policy",
      subCategories: [
        {
          name: "Conversion policy",
          link: "/resources/conversion-policy",
        },
      ],
    },
    { name: "Contact Us", link: "/contact-us" },
    { name: "Request a demo", link: "/request-demo" },
  ];

  const handleMouseEnter = (index) => {
    setHoveredMenu(index);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(-1);
  };

  const handleSubClick = (index) => {
    setOpenSub(index === openSub ? -1 : index);
  };

  const subMenuClickHandler = async (id) => {
    await navigate("/solutions");

    if (window.location.pathname === "/solutions") {
      scrollToWithOffset(id, 200)();
    }
  };

  const scrollToWithOffset = (id, offset) => () => {
    const callMe = () => {
      const element = document.getElementById(id);

      if (element) {
        const offsetTop = element.offsetTop - offset;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      } else {
        console.error(`Element with id '${id}' not found`);
      }
    };

    setTimeout(callMe, 500);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <AppBar
      position={trigger ? "fixed" : "static"}
      sx={{
        backgroundColor: "white",
        color: "#000",
        boxShadow: "none",
        borderBottom: "0.8px solid #17233D66",
        paddingY: "5px",
      }}
    >
      <CustomContainer>
        <Container>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              [theme.breakpoints.up("md")]: {
                paddingLeft: "0",
                paddingRight: "0",
              },
              [theme.breakpoints.down("lg")]: {
                paddingLeft: "0",
                paddingRight: "0",
                minHeight: "64px",
              },
            }}
          >
            <ListItemIcon>
              <Link to={"/"}>
                <img src={logo} alt="metr" srcSet="" />
              </Link>
            </ListItemIcon>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              {menuItems.map((menuItem, index) => (
                <NavLink
                  key={index}
                  to={menuItem.link}
                  style={{
                    textDecoration: "none",
                    position: menuItem.subCategories ? "relative" : "initial",
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Button
                    sx={{
                      textTransform: "none",
                      fontWeight: "400",
                      color: "var(--text-primary)",
                      fontSize: "16px",
                      "&:hover": {
                        color: "var(--secondary)",
                      },
                    }}
                    variant="text"
                  >
                    {index === menuItems.length - 1 ? (
                      <Box
                        component={"span"}
                        sx={{
                          color: "var(--white)",
                          borderRadius: "100px",
                          background: "var(--secondary)",
                          padding: "5px 1rem",
                          textTransform: "none",
                          fontWeight: "400",
                          fontSize: "16px",
                          maxHeight: "40px",
                          boxShadow:
                            "background-color: rgb(255, 147, 30); border-radius: 30px; box-shadow: rgba(100, 100, 100, 0.2) 0px 8px 16px 0px, rgba(80, 80, 80, 0.19) 0px 6px 20px 0px",
                          "&:hover": {
                            backgroundColor: "var(--button-hover)",
                          },
                        }}
                      >
                        Request a demo
                      </Box>
                    ) : (
                      menuItem.name
                    )}
                  </Button>
                  {menuItem.subCategories && hoveredMenu === index && (
                    <div className="submenu">
                      {menuItem.subCategories.map((subItem, subIndex) => (
                        <MenuItem
                          key={subIndex}
                          onClick={() =>
                            subMenuClickHandler(subItem.link.split("/")[1])
                          }
                          sx={{
                            color: "var(--text-primary)",
                            "&:hover": {
                              color: "var(--text-hover)",
                            },
                          }}
                        >
                          {subItem.icon && (
                            <ListItemIcon sx={{ marginRight: "-6px" }}>
                              <img src={subItem.icon} alt="" />
                            </ListItemIcon>
                          )}
                          {subItem.name}
                        </MenuItem>
                      ))}
                    </div>
                  )}
                </NavLink>
              ))}
            </Stack>
            {/* Display Drawer on smaller screens */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Button onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ color: "black" }} />
              </Button>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <List
                  sx={{
                    minWidth: "250px",
                  }}
                >
                  {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.subCategories ? (
                        <>
                          <ListItem
                            component={NavLink}
                            to={item.link}
                            sx={{ color: "var(--text-primary)" }}
                          >
                            <ListItemText primary={item.name} />
                            {item.subCategories &&
                              (openSub === index ? (
                                <ExpandLess
                                  onClick={() => handleSubClick(index)}
                                />
                              ) : (
                                <ExpandMore
                                  onClick={() => handleSubClick(index)}
                                />
                              ))}
                          </ListItem>
                          <Collapse
                            in={openSub === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              component="div"
                              disablePadding
                              sx={{
                                paddingLeft: "10px",
                              }}
                            >
                              {item.subCategories.map((subItem, subIndex) => (
                                <ListItem
                                  key={subIndex}
                                  onClick={() => {
                                    subMenuClickHandler(
                                      subItem.link.split("/")[1]
                                    );
                                    toggleDrawer(false);
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {subItem.icon && (
                                    <ListItemIcon
                                      sx={{
                                        marginRight: "-25px",
                                      }}
                                    >
                                      <Box
                                        component={"img"}
                                        sx={{
                                          maxWidth: "20px",
                                          maxHeight: "20px",
                                        }}
                                        src={subItem.icon}
                                        alt={subItem.name}
                                      />
                                    </ListItemIcon>
                                  )}
                                  <ListItemText primary={subItem.name} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      ) : (
                        <ListItem
                          component={NavLink}
                          to={item.link}
                          sx={{ color: "var(--text-primary)" }}
                          onClick={toggleDrawer(false)}
                        >
                          {index === menuItems.length - 1 ? (
                            <RequestDemo />
                          ) : (
                            <ListItemText primary={item.name} />
                          )}
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </CustomContainer>
    </AppBar>
  );
}

export default Header;
