import React from "react";
import Banner from "../components/Banner";
import Solutions from "../components/Solutions";
import Benefits from "../components/Benefits";
import BlogCards from "../components/BlogCards";
import { Box, Container } from "@mui/material";
import { Link } from "react-router-dom";
import CustomContainer from "../components/CustomContainer";
import RequestDemo from "../components/RequestDemo";

import Calculator from "./Calculator";

function Main() {
  return (
    <CustomContainer>
      <Container>
        <Banner />
        <Solutions />
        <Calculator />
        <Benefits />
        <BlogCards />
        <Box
          component="div"
          paddingY={"3rem"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Link to={"/request-demo"}>
            <RequestDemo
              fsize="16px"
              bgColor="var(--secondary)"
              bgHover="var(--secondary-hover)"
            />
          </Link>
        </Box>
      </Container>
    </CustomContainer>
  );
}

export default Main;
