import React from "react";
import solBanner from "../assets/solBanner.png";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TryNowBanner = ({ title }) => {
  const theme = useTheme();
  return (
    <Grid container sx={{ marginY: "2rem" }}>
      <Grid
        item
        md={12}
        className="background-image"
        style={{
          minHeight: "350px",
          maxHeight: "350px",
          width: "100%",
          borderRadius: "5px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          objectFit: "cover",
          backgroundSize: "cover",
          backgroundImage: `url(${solBanner})`,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
          }}
        ></div>
        <Typography
          component={"h1"}
          sx={{
            zIndex: 1,
            textAlign: "center",
            maxWidth: "750px",
            fontWeight: "700",
            color: "var(--white)",
            fontSize: {
              xs: "22px",
              sm: "25px",
              md: "30px",
              lg: "35px",
            },

            lineHeight: {
              xs: "35px",
              sm: "40px",
              md: "45px",
              lg: "47px",
            },
          }}
        >
          <span
            style={{
              color: "var(--text-hover)",
            }}
          >
            {title.split(" ")[0]}{" "}
          </span>

          <Box component={"span"} sx={{ color: "var(--secondary)" }}>
            {title.split(" ")[1]}{" "}
          </Box>
        </Typography>
        <Typography
          sx={{
            zIndex: 1,
            lineHeight: "25px",
            paddingX: "10px",
            fontSize: "17px",
            color: "var(--ourSol-card)",
            fontWeight: "400",
            textAlign: "center",
            maxWidth: "900px",

            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
          }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex maiores
          adipisci sapiente exercitationem recusandae.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TryNowBanner;
