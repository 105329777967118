import React, { useState } from "react";
import CustomContainer from "../components/CustomContainer";
import { Box, Container, Grid, Switch, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PricingCard from "../components/Buy/PricingCard";
import FetchData from "../components/Fetch";

const Buy = () => {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);

  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/subscription-package-infos";
  const { data } = FetchData({ url: apiUrl });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Box
          sx={{
            backgroundColor: "var(--button)",
            width: "100%",
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "3rem",

            [theme.breakpoints.down("sm")]: {
              minHeight: "500px",
            },
          }}
        >
          <Typography
            component={"h1"}
            sx={{
              zIndex: 1,
              textAlign: "center",
              maxWidth: "750px",
              fontWeight: "700",
              color: "var(--white)",
              fontSize: {
                xs: "28px",
                sm: "35px",
                md: "38px",
                lg: "40px",
              },

              lineHeight: {
                xs: "40px",
                sm: "55px",
                md: "55px",
                lg: "60px",
              },
            }}
          >
            Package And Subscription Types
          </Typography>
          <Typography
            sx={{
              zIndex: 1,
              marginTop: "2rem",
              lineHeight: "28.13px",
              paddingX: "10px",
              fontSize: "20px",
              color: "var(--ourSol-card)",
              fontWeight: "300",
              textAlign: "center",
              maxWidth: "900px",

              [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
              },
            }}
          >
            Transform your content effortlessly with{" "}
            <span
              style={{
                color: "var(--text-hover)",
                fontWeight: "bold",
              }}
            >
              met
            </span>
            <span
              style={{
                color: "var(--secondary)",
                fontWeight: "bold",
              }}
            >
              R
            </span>{" "}
            , the leading tool for converting <strong>DOCX</strong>,{" "}
            <strong>HTML</strong>, and <strong>Markdown</strong> files to
            DITA-XML format. Perfectly designed for technical writers,
            documentation teams, and content managers, metR streamlines your
            content workflow, ensuring consistency and efficiency.
          </Typography>

          <Box marginTop={5}>
            <span style={{ color: "var(--white)" }}>Monthly</span>
            <Switch
              name="loading"
              checked={checked}
              onChange={handleChange}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "#ff5722 !important",
                },
                "& .MuiSwitch-thumb": {
                  color: "#ff5722",
                },
              }}
            />

            <span style={{ color: "var(--white)" }}>Annual</span>
          </Box>

          {/* <Typography
            sx={{
              zIndex: 1,
              lineHeight: "28.13px",
              paddingX: "10px",
              fontSize: "14px",
              color: "rgb(249, 42, 42)",
              fontWeight: "300",
              textAlign: "center",
              maxWidth: "900px",

              [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
              },
            }}
          >
            *Currently we are supporting only three document formats for
            conversion to DITA-XML. In the future releases we will include more
            document formats
          </Typography> */}
        </Box>
        <Box
          sx={{
            marginTop: "-7rem",
          }}
        >
          <Container>
            <Grid container spacing={4} justifyContent="center" mb={5}>
              {data?.data.map((plan, index) => (
                <Grid
                  item
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <PricingCard
                    plan={plan?.attributes?.Package_Type}
                    docVolumeMonthly={
                      plan?.attributes?.Features?.monthly_docVolume
                    }
                    docVolumeYearly={
                      plan?.attributes?.Features?.yearly_docVolume
                    }
                    features={plan?.attributes?.Features?.features}
                    checked={checked}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default Buy;
