import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RequestDemo from "../RequestDemo";
import solBanner from "../../assets/solBanner.png";
import { Link } from "react-router-dom";

function BannerSolution() {
  const theme = useTheme();
  return (
    <Box component={"div"} sx={{ paddingY: "2rem" }}>
      <Grid container>
        <Grid
          item
          md={12}
          className="background-image"
          style={{
            minHeight: "350px",
            maxHeight: "350px",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            backgroundSize: "cover",
            backgroundImage: `url(${solBanner})`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
            }}
          ></div>
          <Typography
            component={"h1"}
            sx={{
              zIndex: 1,
              textAlign: "center",
              maxWidth: "750px",
              fontWeight: "700",
              color: "var(--white)",
              fontSize: {
                xs: "22px",
                sm: "25px",
                md: "30px",
                lg: "35px",
              },

              lineHeight: {
                xs: "35px",
                sm: "40px",
                md: "45px",
                lg: "47px",
              },
            }}
          >
            Publish DITA-XML Content effortlessly with{" "}
            <Box
              component={"span"}
              sx={{
                color: "var(--secondary)",
                fontSize: {
                  xs: "35px",
                  sm: "40px",
                  md: "45px",
                  lg: "50px",
                },
              }}
            >
              metR
            </Box>
          </Typography>
          <Typography
            sx={{
              zIndex: 1,
              marginY: "1rem",
              lineHeight: "25px",
              paddingX: "10px",
              fontSize: "17px",
              color: "var(--ourSol-card)",
              fontWeight: "400",
              textAlign: "center",
              maxWidth: "900px",

              [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
              },
            }}
          >
            Streamline your unstructured to DITA-XML content method and edit or
            publish the DITA content using the DITAxPresso interoperability
            utility toolkit. Manage DITA content sources from GitHub, DITA CCMS,
            or any branded Cloud Platforms for a cost-effective solution.
          </Typography>
          <Link
            to={"/request-demo"}
            className="requestButton"
            style={{ zIndex: "1" }}
          >
            <RequestDemo />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BannerSolution;
