import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button"; // Corrected import

import { useNavigate } from "react-router-dom";

import sendEmail from "../components/SendEmail";
const smtp_sendTo = process.env.REACT_APP_SMTP_SEND_EMAIL_TO;
const smtp_sendTo2 = process.env.REACT_APP_SMTP_SEND_EMAIL_TO2;

const Survey = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    que1: "",
    que2: "",
    que3: "",
    que4: "",
    que5: "",
    que6: "",
    que7: "",
    que8: "",
    que9: "",
    que10: "",
    que11: "",
    que12: "",
    que13: "",
    que14: "",
  });

  const [additionalFields, setAdditionalFields] = useState({
    que4_other: "",
    que7_other: "",
    que9_other: "",
    que13_other: "",
  });

  const [errors, setErrors] = useState({
    que1: "",
    que2: "",
    que3: "",
    que4: "",
    que5: "",
    que6: "",
    que7: "",
    que8: "",
    que9: "",
    que10: "",
    que11: "",
    que12: "",
    que13: "",
    que14: "",
    que4_other: "",
    que7_other: "",
    que9_other: "",
    que13_other: "",
  });

  const validateField = (name, value) => {
    let error = "";
    if (
      [
        "que1",
        "que2",
        "que3",
        "que4_other",
        "que7_other",
        "que9_other",
        "que13_other",
      ].includes(name)
    ) {
      if (value.length < 50) {
        error = "Response must be at least 50 characters.";
      } else if (value.length > 200) {
        error = "Response cannot exceed 200 characters.";
      }
    } else if (
      (name === "que4" ||
        name === "que5" ||
        name === "que6" ||
        name === "que7" ||
        name === "que8" ||
        name === "que9" ||
        name === "que10" ||
        name === "que11" ||
        name === "que12" ||
        name === "que13" ||
        name === "que14") &&
      value === ""
    ) {
      error = "Please select an appropriate option.";
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });

    // Show or hide additional text fields based on the "Others" selection
    if (name.startsWith("que") && value === "Others") {
      setAdditionalFields({
        ...additionalFields,
        [`${name}_other`]: "",
      });
    } else {
      setAdditionalFields({
        ...additionalFields,
        [`${name}_other`]: null,
      });
    }
  };

  const handleAdditionalChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setAdditionalFields({
      ...additionalFields,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {
      que1: "",
      que2: "",
      que3: "",
      que4: "",
      que5: "",
      que6: "",
      que7: "",
      que8: "",
      que9: "",
      que10: "",
      que11: "",
      que12: "",
      que13: "",
      que14: "",
      que4_other: "",
      que7_other: "",
      que9_other: "",
      que13_other: "",
    };

    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      newErrors[key] = error;
    });

    Object.keys(additionalFields).forEach((key) => {
      if (additionalFields[key] !== null) {
        const error = validateField(key, additionalFields[key]);
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "")) {
      setLoading(true);
      try {
        let emailBody = `
            Hello metR admin, <br/>
            <p>Find below survey form submission details</p>
          `;

        Object.entries(formData).forEach(([key, value], index) => {
          const label = document.querySelector(`label[for=${key}]`);
          const question = label
            ? label.textContent
            : `[Question ${index + 1}]`;

          emailBody += `<b>${question}</b><br/>`;
          emailBody += `<b>Answer:</b> ${value}<br/><br/><br/>`;

          if (value === "Others" && additionalFields[`${key}_other`]) {
            emailBody += `<b>Additional Information:</b> ${
              additionalFields[`${key}_other`]
            }<br/><br/><br/>`;
          }
        });

        emailBody += `Best Regards <br/> metR`;

        try {
          await sendEmail({
            mailTo: `${smtp_sendTo}, ${smtp_sendTo2}`,
            subject: "Received docMigration survey from metR user",
            mailBody: emailBody,
          });
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
        setLoading(false);
        navigate("/try-now-migration");
      } catch (error) {
        setLoading(false);
        console.error("Error sending survey email", error);
      }
    }
  };

  const renderSelectField = (label, name, options) => (
    <Grid
      item
      xs={12}
      sx={{ marginTop: "8px", display: "flex", flexDirection: "column" }}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        id={name}
        name={name}
        onChange={handleChange}
        value={formData[name]}
        error={!!errors[name]}
        displayEmpty
        sx={{ marginTop: "10px" }}
      >
        <MenuItem value="" disabled>
          Select an option
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error={Boolean(errors[name])}>
        {errors[name]}
      </FormHelperText>

      {formData[name] === "Others" && (
        <TextField
          id={`${name}_other`}
          fullWidth
          multiline
          rows={4}
          name={`${name}_other`}
          onChange={handleAdditionalChange}
          value={additionalFields[`${name}_other`]}
          error={!!errors[`${name}_other`]}
          helperText={errors[`${name}_other`]}
          sx={{ marginTop: "10px" }}
          placeholder="Please specify..."
        />
      )}
    </Grid>
  );

  const handleSkip = async (e) => {
    e.preventDefault();
    navigate("/try-now-migration");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 600, mx: "auto", marginBottom: "2rem" }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          marginBottom: "1rem",
        }}
      >
        DITA Conversion
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor="que1">
            Can you describe your current content management system and process?
          </label>
          <TextField
            id="que1"
            fullWidth
            multiline
            rows={4}
            name="que1"
            onChange={handleChange}
            value={formData.que1}
            error={!!errors.que1}
            helperText={errors.que1}
            sx={{ marginTop: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <label htmlFor="que2">
            What tools or platforms are you using to create, manage, and store
            your content?
          </label>
          <TextField
            id="que2"
            fullWidth
            multiline
            rows={4}
            name="que2"
            onChange={handleChange}
            value={formData.que2}
            error={!!errors.que2}
            helperText={errors.que2}
            sx={{ marginTop: "10px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "8px" }}>
          <label htmlFor="que3">
            How many users are involved in your content management process?
          </label>
          <TextField
            id="que3"
            fullWidth
            multiline
            rows={4}
            name="que3"
            onChange={handleChange}
            value={formData.que3}
            error={!!errors.que3}
            helperText={errors.que3}
            sx={{ marginTop: "10px" }}
          />
        </Grid>
        {renderSelectField(
          "What types of documents are you currently working with?",
          "que4",
          ["DOCX", "HTML", "Markdown", "Others"]
        )}
        {renderSelectField(
          "How do you currently manage and organize your content?",
          "que5",
          [
            "Manual organization",
            "Automated tools",
            "Content management system",
          ]
        )}
        {renderSelectField(
          "How do you ensure content consistency and quality across your documents?",
          "que6",
          [
            "Manual reviews",
            "Automated quality checks",
            "Style guides and templates",
          ]
        )}
        {renderSelectField(
          "What challenges are you facing with your current content formats?",
          "que7",
          [
            "Lack of structure",
            "Difficulty in maintaining consistency",
            "Challenges in reusing content",
            "Inefficient search and retrieval",
            "Others",
          ]
        )}
        {renderSelectField(
          "Have you considered converting your content to a structured format like DITA-XML?",
          "que8",
          ["Yes", "No", "Considering it"]
        )}

        {renderSelectField(
          "What are your main goals for content conversion?",
          "que9",
          [
            "Improving content reuse",
            "Enhancing content consistency and quality",
            "Simplifying content updates and maintenance",
            "Facilitating better content search and retrieval",
            "Others",
          ]
        )}

        {renderSelectField(
          "How many documents would you be looking to convert to DITA-XML?",
          "que10",
          ["Less than 100", "100-500", "500-1000", "More than 1000"]
        )}

        {renderSelectField(
          "What is the estimated volume of content updates or new content creation per month?",
          "que11",
          [
            "Less than 10 documents",
            "10-50 documents",
            "50-100 documents",
            "More than 100 documents",
          ]
        )}

        {renderSelectField(
          "What is your timeline for implementing a content conversion project?",
          "que12",
          [
            "Immediate (within the next 1-3 months)",
            "Short-term (within the next 3-6 months)",
            "Long-term (within the next year)",
            "No specific timeline",
          ]
        )}

        {renderSelectField(
          "Are there any specific features or capabilities you are looking for in a content conversion solution?",
          "que13",
          [
            "Automated conversion",
            "Content editing and management tools",
            "Multi-channel publishing",
            "Integration with existing systems",
            "Others",
          ]
        )}

        {renderSelectField(
          "Would you be interested in a demo of our content conversion toolkit?",
          "que14",
          ["Yes", "No", "Maybe later"]
        )}
        <Grid item xs={12} mt={4} sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              minWidth: "120px",
              maxHeight: "40px",
              minHeight: "40px",
              background: "var(--secondary)",
              color: "var(--white)",
              display: "inline-block",
              borderRadius: "100px",
              fontSize: "16px",
              fontWeight: "400",

              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
            }}
          >
            {loading ? (
              <CircularProgress
                color="success"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
                  maxWidth: "23px",
                  maxHeight: "23px",
                  color: "white",
                }}
              />
            ) : (
              "Submit"
            )}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSkip}
            sx={{
              minWidth: "120px",
              background: "var(--button)",
              color: "var(--white)",
              display: "inline-block",
              borderRadius: "100px",
              fontSize: "16px",
              fontWeight: "400",
              marginLeft: "1rem",

              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
            }}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Survey;
