import CustomContainer from "../components/CustomContainer";
import FetchData from "../components/Fetch";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ResourcesQA from "../components/ResourcesQA";

function Resources() {
  const theme = useTheme();
  const apiUrl =
    process.env.REACT_APP_API_URL + "/api/privacy-policies?populate=*";
  const { data } = FetchData({ url: apiUrl });

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <ResourcesQA data={data} title={"Document Conversion policy"} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: "3rem",
            }}
          ></Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default Resources;
