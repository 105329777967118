import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomContainer from "../CustomContainer";
import FetchData from "../Fetch";

function FAQ() {
  const apiUrl = process.env.REACT_APP_API_URL + "/api/faqs?populate=*";
  const { data } = FetchData({ url: apiUrl });

  return (
    <Box
      component="div"
      sx={{
        marginBottom: "4rem",
      }}
    >
      <Box
        component={"div"}
        sx={{ background: "var(--gradient-color)", paddingY: "2rem" }}
      >
        <CustomContainer>
          <Container>
            <Typography
              align="center"
              sx={{
                fontSize: "42px",
                fontWeight: "700",
                color: "var(--text-primary)",
              }}
            >
              FAQs
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "var(--text-gray)",
                paddingY: "2rem",
              }}
            >
              Down below, are some frequently asked questions by our customers.
              These might help you solve your queries as well.
            </Typography>
          </Container>
        </CustomContainer>
      </Box>
      <CustomContainer>
        <Container>
          <Box component={"div"} sx={{ marginBottom: "2rem" }}>
            {data?.data?.map((queAns, index) => (
              <Accordion
                key={index}
                sx={{
                  background: "var(--ourSol-card)",
                  border: "1px solid var(--border)",
                  padding: "4px 16px",
                  marginBottom: "1rem",
                  borderRadius: "4px",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "var(--secondary)" }} />
                  }
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>
                    {queAns.attributes.Question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    component={"span"}
                    sx={{ fontSize: "16px", fontWeight: "400" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: queAns.attributes.Answer,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default FAQ;
