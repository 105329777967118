import { useEffect, useState } from "react";

function FetchData({ url }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const fetchedData = await response.json();
        setData(fetchedData);
        setError(null);
      } catch (error) {
        console.error(
          "Error fetching data:",
          "Data is not addded in backend",
          error
        );
        setError(error.message || "Failed to fetch data");
        setData(null);
      }
    };

    fetchData();
  }, [url]);

  return { data, error };
}

export default FetchData;
