import React from "react";

import { Container, Box, Typography, Grid } from "@mui/material";

import CustomContainer from "../components/CustomContainer";
import { useTheme } from "@mui/material/styles";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import solBanner from "../assets/solBanner.png";

import CalendlyEmbed from "../components/CalendlyEmbed";

function RequestPage() {
  const theme = useTheme();

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <Grid container sx={{ marginY: "2rem" }}>
            <Grid
              item
              md={12}
              className="background-image"
              style={{
                minHeight: "350px",
                maxHeight: "350px",
                width: "100%",
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundImage: `url(${solBanner})`,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              ></div>
              <Typography
                component={"h1"}
                sx={{
                  zIndex: 1,
                  textAlign: "center",
                  maxWidth: "750px",
                  fontWeight: "700",
                  color: "var(--white)",
                  fontSize: {
                    xs: "22px",
                    sm: "25px",
                    md: "30px",
                    lg: "35px",
                  },

                  lineHeight: {
                    xs: "35px",
                    sm: "40px",
                    md: "45px",
                    lg: "47px",
                  },
                }}
              >
                Request a{" "}
                <Box
                  component={"span"}
                  sx={{
                    color: "var(--secondary)",
                    fontSize: {
                      xs: "35px",
                      sm: "40px",
                      md: "45px",
                      lg: "50px",
                    },
                  }}
                >
                  Demo
                </Box>{" "}
                with our team
              </Typography>
              {/* <Typography
                sx={{
                  zIndex: 1,
                  marginY: "2rem",
                  lineHeight: "28.13px",
                  paddingX: "10px",
                  fontSize: "20px",
                  color: "var(--ourSol-card)",
                  fontWeight: "400",
                  textAlign: "center",
                  maxWidth: "900px",

                  [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                  },
                }}
              >
                Ready to deliver exceptional content experiences? Fill out the
                form and get the conversation started.
              </Typography> */}
            </Grid>
          </Grid>
          <Typography
            variant="h2"
            sx={{
              fontSize: "30px",
              color: "var(--text-primary)",
              fontWeight: "500",
              marginTop: "2rem",
              marginX: "auto",
              maxWidth: "900px",
              textAlign: "center",
            }}
          >
            {/* <span
              style={{
                color: "var(--text-hover)",
                fontWeight: "bold",
              }}
            >
              met
            </span>
            <span
              style={{
                color: "var(--secondary)",
                fontWeight: "bold",
              }}
            >
              R
            </span>{" "} */}
          </Typography>
          <Box component={"div"}>
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: "0",
                marginTop: "0",
              }}
            >
              <Box
                sx={{
                  padding: "1rem",
                  marginTop: "2rem",
                  border: "1px solid var(--border)",
                  borderRadius: "6px",
                  maxWidth: "500px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  marginX: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <PhoneIcon
                    sx={{
                      color: "var(--button)",
                    }}
                  />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                    }}
                  >
                    <b>Line 1</b>:{" "}
                    <a className="underlined__atag" href="tel:+91-839-090-5726">
                      +91-839-090-5726
                    </a>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <PhoneIcon
                    sx={{
                      color: "var(--button)",
                    }}
                  />
                  <Typography sx={{ marginLeft: "10px" }}>
                    <b>Line 2</b>:{" "}
                    <a className="underlined__atag" href="tel:+91-742-096-5726">
                      +91-742-096-5726
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon
                    sx={{
                      color: "var(--button)",
                    }}
                  />
                  <Typography sx={{ marginLeft: "10px" }}>
                    <b>Info</b>:
                    <a
                      className="underlined__atag"
                      href="mailto:metr@metapercept.com"
                    >
                      metr@metapercept.com
                    </a>
                  </Typography>
                </Box>
              </Box>
            </ul>
          </Box>
          <Box
            component={"div"}
            sx={
              {
                // background: "var(--contact-gradient)",
                // borderRadius: "6px",
              }
            }
          >
            <Grid
              container
              sx={{
                display: "flex",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                item
                md={12}
                sx={{
                  paddingRight: "1rem",
                  [theme.breakpoints.down("md")]: {
                    paddingRight: "0",
                    marginBottom: "2rem",
                  },
                }}
              >
                <CalendlyEmbed />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default RequestPage;
