import { Box, Grid, Typography } from "@mui/material";
import SliderROI from "./SliderROI";

const SliderItem = ({ label, name, value, handleChange }) => {
  const gridItemProps =
    name === "costReduction" ? { xs: 12 } : { xs: 12, md: 6 };
  return (
    <Grid item {...gridItemProps}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "15px" }} gutterBottom>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "15px" }} gutterBottom>
          {value}
          <span
            style={{
              marginLeft: "1px",
              fontSize: "14px",
            }}
          >
            %
          </span>
        </Typography>
      </Box>
      <SliderROI name={name} value={value} handleChange={handleChange} />
    </Grid>
  );
};
export default SliderItem;
