import React from "react";

import { InlineWidget } from "react-calendly";
const CalendlyEmbed = () => {
  return (
    <div className="App">
      <InlineWidget
        styles={{
          height: "800px",
        }}
        url="https://calendly.com/metr-metapercept/metr-demo"
      />
    </div>
  );
};

export default CalendlyEmbed;
