import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import docMigrationImg from "../assets/user-doc-migration.svg";
import docManagerImg from "../assets/user-doc-manager.svg";
import docEditorImg from "../assets/user-doc-editor.svg";
import docPublisherImg from "../assets/user-doc-publisher.svg";
import fileReplace from "../assets/file-replace-outline.svg";
import fileDoc from "../assets/file-document-outline.svg";
import fileFile from "../assets/file-document-edit.svg";
import filePub from "../assets/file-publisher-outline.svg";
import splitByCapital from "./WordSplitter";

const cardItems = [
  {
    name: "DocManager",
    link: "/DocManager",
    desc: "Streamline your document chaos with DocManager – your ultimate solution for organized, efficient content management.",
    img: docMigrationImg,
    icon: fileReplace,
  },
  {
    name: "DocEditor",
    link: "/DocEditor",
    desc: "Empower your team to craft compelling content effortlessly with DocEditor – where creativity meets productivity.",
    img: docManagerImg,
    icon: fileDoc,
  },
  {
    name: "DocPublisher",
    link: "/DocPublisher",
    desc: "Accelerate your content delivery and captivate your audience with DocPublisher – the key to seamless publishing success.",
    img: docEditorImg,
    icon: fileFile,
  },
  {
    name: "DocMigration",
    link: "/DocMigration",
    desc: "Make your move confidently using DocMigration – your trusted partner for hassle-free content transition and optimization.",
    img: docPublisherImg,
    icon: filePub,
  },
];

function Solutions() {
  const theme = useTheme();
  const navigate = useNavigate();

  const subMenuClickHandler = async (id) => {
    await navigate("/solutions");

    if (window.location.pathname === "/solutions") {
      scrollToWithOffset(id, 200)();
    }
  };

  const scrollToWithOffset = (id, offset) => () => {
    const callMe = () => {
      const element = document.getElementById(id);

      if (element) {
        const offsetTop = element.offsetTop - offset;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      } else {
        console.error(`Element with id '${id}' not found`);
      }
    };

    setTimeout(callMe, 500);
  };

  return (
    <Box component="div" sx={{ paddingTop: "64px" }}>
      <Typography
        align="center"
        sx={{
          fontSize: "42px",
          fontWeight: "700",
          color: "var(--text-primary)",
        }}
      >
        Our Solutions
      </Typography>

      <Box component={"div"} sx={{ paddingY: "2rem" }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 0, sm: 2 }}
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          {cardItems.map((card, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                component="div"
                sx={{
                  // backgroundColor: "var(--card-bg-secondary)",
                  background: "var(--button)",
                  borderRadius: "8px",
                  padding: "2rem",
                  paddingBottom: "0",
                }}
              >
                <Typography
                  align="center"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "var(--white)",
                  }}
                >
                  <span
                    style={{
                      color: "var(--text-hover)",
                    }}
                  >
                    {splitByCapital(card.name)[0]}
                  </span>
                  <Box component={"span"} sx={{ color: "var(--secondary)" }}>
                    {splitByCapital(card.name)[1]}
                  </Box>
                </Typography>
                <Typography
                  paragraph={true}
                  align="center"
                  sx={{
                    color: "var(--white)",
                    marginBottom: "0",
                    paddingTop: "10px",
                    paddingBottom: "8px",
                    lineHeight: "21.09px",
                  }}
                >
                  {card.desc}
                </Typography>

                <Typography
                  onClick={() => subMenuClickHandler(card.link.split("/")[1])}
                  style={{ cursor: "pointer" }}
                  align="center"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "var(--text-hover)",
                    transition: "all 0.3s",
                  }}
                >
                  Read More
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    maxWidth: "520px",
                    margin: "0 auto",
                    marginTop: "2rem",
                  }}
                >
                  <img src={card.img} alt={card.name} />
                  <Box
                    component="div"
                    sx={{
                      maxWidth: "30px",
                      position: "absolute",
                      top: "50%",
                      right: "-25px",
                      backgroundColor: "var(--secondary)",
                      padding: "10px",
                      borderRadius: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      [theme.breakpoints.down("sm")]: {
                        maxWidth: "20px",
                      },
                    }}
                  >
                    <img src={card.icon} alt={card.name} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Solutions;
