import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

function Blog() {
  const theme = useTheme();
  const [data, setData] = useState(null);
  // const [error, setError] = useState(null);

  const apiUrl =
    "https://blog-adminpanel.metapercept.com/api/articles?populate=*&filters[Category][Title][$in][1]=Case Study&filters[Category][Title][$in][2]=White Paper";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        let fetchedData = await response.json();

        setData(fetchedData);
        // setError(null);
      } catch (error) {
        console.error(
          "Error fetching data:",
          "Data is not addded in backend",
          error
        );

        setData(null);
      }
    };

    fetchData();
  }, []);

  const allowedTag = [
    "DITA-XML",
    "Structured Authoring",
    "CCMS",
    "Artificial Intelligence",
    "XML",
  ];
  return (
    <Box component="div" sx={{ paddingTop: "64px" }}>
      <Typography
        align="center"
        sx={{
          fontSize: "42px",
          fontWeight: "700",
          color: "var(--text-primary)",
        }}
      >
        Recently released Post
      </Typography>

      <Box component={"div"} sx={{ paddingY: "2rem" }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 0, sm: 2 }}
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          {data?.data
            .reverse()
            .slice(0, 3)
            .map((item, index) => {
              if (item.attributes && allowedTag.includes(item.attributes.Tag)) {
                return (
                  <Grid item xs={12} md={4} key={index}>
                    <Link
                      to={`https://blog.metapercept.com/highlight-single/${item?.attributes?.Slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          height: "100%",
                          flexDirection: "column",
                          padding: "10px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            minHeight: "325px",
                            maxHeight: "325px",
                            borderRadius: "8px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            [theme.breakpoints.down("md")]: {
                              maxHeight: "auto",
                            },
                          }}
                          src={`https://blog-adminpanel.metapercept.com/${item?.attributes?.Image?.data[0]?.attributes?.url}`}
                          alt={item?.attributes?.Title}
                        />

                        <Typography
                          align="left"
                          sx={{
                            marginTop: "1rem",
                            fontSize: "20px",
                            fontWeight: "500",
                            color: "var(--t}ext-primary)",
                            "&:hover": {
                              color: "var(--text-hover)",
                            },
                          }}
                        >
                          {item?.attributes?.Title}
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                );
              }
              return null;
            })}
        </Grid>
      </Box>
    </Box>
  );
}

export default Blog;
