import React from "react";
import { Box, Typography, Grid } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import CustomContainer from "./CustomContainer";

function ResourcesQA({ data, title }) {
  // const theme = useTheme();
  // const [queList, setQueList] = useState([]);

  // useEffect(() => {
  //   const question__wrapper = document.querySelector(".question__wrapper");

  //   if (question__wrapper) {
  //     const elementsWithNameQue1 = question__wrapper.querySelectorAll("[name]");

  //     const namesArray = [];

  //     elementsWithNameQue1.forEach((ele) => {
  //       namesArray.push(ele.getAttribute("name"));
  //     });

  //     // setQueList(namesArray);
  //   }
  // }, [data]);

  let wordsArray = title.split(" ");
  let [, ...restWords] = wordsArray;

  return (
    <>
      <Box component={"div"} sx={{ marginY: "2rem" }}>
        <Typography
          component={"h1"}
          align="center"
          sx={{
            marginTop: "2rem",
            fontSize: "42px",
            fontWeight: "700",
            color: "var(--text-primary)",
          }}
        >
          <span style={{ color: "var(--secondary)" }}>{wordsArray[0]} </span>
          <span style={{ color: "var(--text-primary)" }}>
            {restWords.join(" ")}
          </span>
        </Typography>
        {/* <Typography
          align="center"
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            color: "var(--list-color)",
            marginTop: "10px",
          }}
        >
          Last updated May 21st 2021
        </Typography> */}
      </Box>
      <Grid>
        {/* <Typography
            sx={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "21.09px",
              color: "var(--text-primary)",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore
            eius impedit iusto praesentium mollitia inventore asperiores ipsam?
            Earum quam voluptatibus eius est blanditiis in. Voluptas quisquam at
            amet non deleniti. Lorem ipsum dolor sit amet consectetur,
            adipisicing elit. Rerum quia sequi amet? Explicabo laborum magni
            laudantium ad nihil est facere rerum illum architecto blanditiis
            ipsum temporibus necessitatibus, ipsa possimus. Praesentium.
          </Typography> */}
        <CustomContainer>
          <Box
            component={"div"}
            className="question__wrapper"
            sx={{
              maxWidth: "900px",
              margin: "0 auto",
              marginTop: "3rem",
            }}
          >
            {data?.data.map((item, index) => (
              <Box
                key={index}
                name={`que${index}`}
                component={"div"}
                sx={{ marginY: "2rem" }}
              >
                <Typography
                  component={"h2"}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "21.09px",
                    fontSize: "20px",

                    color: "var(--text-primary)",
                  }}
                >
                  {index + 1}. {item?.attributes?.Question}
                </Typography>
                <Box
                  component={"div"}
                  sx={{
                    lineHeight: "21.09px",
                    fontSize: "16px",
                    color: "var(--list-color)",
                    marginTop: "8px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.attributes?.Answer,
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </CustomContainer>
      </Grid>
      {/* <Grid container spacing={2} sx={{ marginTop: "2rem" }}> */}
      {/* <Grid
          item
          xs={4}
          sx={{
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "2rem",
              position: "sticky",
              top: "6rem",
            }}
          >
            <Box
              className="sidebar__question"
              data=""
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid var(--border)",
              }}
            >
              {data?.data?.map((que, index) =>
                queList[index] ? (
                  <Link
                    to={queList[index]}
                    key={index}
                    smooth={true}
                    duration={500}
                    className="solButton"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginBottom:
                        index === data.data.length - 1 ? "2rem" : "1rem",
                      color: "var(--text-primary)",
                    }}
                  >
                    {que?.attributes?.Question}
                  </Link>
                ) : null
              )}
            </Box>
          </Box>
        </Grid> */}
      {/* </Grid> */}
    </>
  );
}

export default ResourcesQA;
