import { Box, Grid } from "@mui/material";
import {
  clearError,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
} from "./FormValidation";

import ReplayIcon from "@mui/icons-material/Replay";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ZohoForm = () => {
  const hiddenInputRef = useRef(null);

  const query = useQuery();

  function privacyAlert1832254000001456002() {
    var privacyTool = document.getElementById("privacyTool1832254000001456002");
    var privacyErr = document.getElementById("privacyErr1832254000001456002");
    if (privacyTool !== undefined && !privacyTool.checked) {
      privacyErr.style.visibility = "visible";
      privacyTool.focus();
      return false;
    }
    return true;
  }

  function disableErr1832254000001456002() {
    var privacyTool = document.getElementById("privacyTool1832254000001456002");
    var privacyErr = document.getElementById("privacyErr1832254000001456002");
    if (
      privacyTool !== undefined &&
      privacyTool.checked &&
      privacyErr !== undefined
    ) {
      privacyErr.style.visibility = "hidden";
    }
  }

  function reloadImg1832254000001456002() {
    var captcha = document.getElementById("imgid1832254000001456002");
    if (captcha.src.indexOf("&d") !== -1) {
      captcha.src =
        captcha.src.substring(0, captcha.src.indexOf("&d")) +
        "&d" +
        new Date().getTime();
    } else {
      captcha.src = captcha.src + "&d" + new Date().getTime();
    }
  }

  const isFormValid = (e) => {
    let inputs = Array.from(e.target);
    // inputs.pop();
    inputs = inputs.slice(5, 12);
    // console.log(inputs.slice(5, 12));
    for (let input of inputs) {
      if (input.value.length === 0) {
        const errorMessage = input.getAttribute("errordiv");
        const errorMessageDiv = document.getElementById(errorMessage);
        errorMessageDiv.style.display = "block";

        errorMessageDiv.innerHTML = "Please fill out this field";
        break;
      }
    }

    // ==========================================
    const ErrorMsg = document.getElementsByClassName("errorMessage");
    let count = 0;
    for (let ele of ErrorMsg) {
      if (ele.style.display === "block") count++;
    }
    if (count) {
      e.preventDefault();
    }
    return count ? false : true;
  };
  function checkMandatory1832254000001456002(e) {
    if (isFormValid(e)) {
      if (privacyAlert1832254000001456002()) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    } else {
      e.preventDefault();
      return false;
    }
  }

  useEffect(() => {
    let planId = query.get("planId") || "";
    let planType = query.get("planType") || "";

    hiddenInputRef.current.value = `metR-Contact-Us-Form (selected Plan: ${planId} (${planType}))`;
  }, [query]);

  return (
    <>
      <style jsx="true">
        {`
          .errorMessage {
            display: none;

            color: #cd5c5c;
          }

          button:disabled {
            background-color: #ffad01 !important;
            cursor: default;
          }
          form i {
            // color: rgb(108, 96, 254);
            color: #fcb713;
          }
          form input,
          form select,
          form textarea {
            //border: none;
            outline: none;
          }
        `}
      </style>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "var(--contact-gradient)",
          borderRadius: "6px",
          paddingY: "1.5rem",
          paddingX: "1.5rem",
        }}
      >
        <div
          id="crmWebToEntityForm"
          className="zcwf_lblLeft crmWebToEntityForm"
        >
          <form
            id="webform1832254000001456002"
            action="https://crm.zoho.com/crm/WebToContactForm "
            name="WebToContacts1832254000001456002"
            method="POST"
            onSubmit={checkMandatory1832254000001456002}
            acceptCharset="UTF-8"
          >
            <Grid container spacing={2}>
              <input
                type="text"
                style={{ display: "none" }}
                name="xnQsjsdp"
                defaultValue="f7b3c0e0b47b03d6e8e36721448f062fcc1e5e9b90b19af02de72d76fddcf570"
              />
              <input type="hidden" name="zc_gad" id="zc_gad" defaultValue="" />
              <input
                type="text"
                style={{ display: "none" }}
                name="xmIwtLD"
                defaultValue="2c9b6516916ff1bc3f0724e46ac40081695d1f5f9351582b480dc9fb7aad8acddc48f3ba8494407fee4ce9a8237481af"
              />
              <input
                type="text"
                style={{ display: "none" }}
                name="actionType"
                defaultValue="Q29udGFjdHM="
              />
              <input
                type="text"
                style={{ display: "none" }}
                name="returnURL"
                value="https://met-r.io/"
              ></input>
              <input
                type="text"
                hidden
                id="CONTACTCF5"
                name="CONTACTCF5"
                maxLength="255"
                defaultValue="metR-Contact-Us-Form"
                ref={hiddenInputRef}
              ></input>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="First_Name">
                      First Name
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="zcwf_col_fld">
                    <input
                      type="text"
                      id="First_Name"
                      name="First Name"
                      maxLength={40}
                      errordiv="errorFIRSTNAME"
                      onBlur={firstNameValidation}
                      onFocus={() => clearError("errorFIRSTNAME")}
                    />
                    <div className="zcwf_col_help" />
                  </div>
                  <p className="errorMessage" id="errorFIRSTNAME">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="Last_Name">
                      Last Name
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="zcwf_col_fld">
                    <input
                      type="text"
                      id="Last_Name"
                      name="Last Name"
                      maxLength={80}
                      errordiv="errorLASTNAME"
                      onBlur={lastNameValidation}
                      onFocus={() => clearError("errorLASTNAME")}
                    />
                    <div className="zcwf_col_help" />
                  </div>
                  <p className="errorMessage" id="errorLASTNAME">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="Title">Title</label>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <div className="zcwf_col_fld">
                    <input
                      type="text"
                      id="Title"
                      name="Title"
                      maxLength="100"
                      errordiv="errorTITLE"
                      onFocus={() => clearError("errorTITLE")}
                    ></input>
                    <div className="zcwf_col_help"></div>
                  </div>
                  <p className="errorMessage" id="errorTITLE">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="Email">
                      Email
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="zcwf_col_fld">
                    <input
                      type="text"
                      ftype="email"
                      autoComplete="false"
                      id="Email"
                      name="Email"
                      crmlabel=""
                      maxLength={100}
                      errordiv="errorEMAIL"
                      onBlur={emailValidation}
                      onFocus={() => clearError("errorEMAIL")}
                    />
                    <div className="zcwf_col_help" />
                  </div>
                  <p className="errorMessage" id="errorEMAIL">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="CONTACTCF2">
                      Country Code
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="zcwf_col_fld">
                    <select
                      className="zcwf_col_fld_slt"
                      id="CONTACTCF2"
                      name="CONTACTCF2"
                    >
                      <option value="-None-">-None-</option>
                      <option value="India (+91)">India (+91)</option>
                      <option value="Australia (+61)">Australia (+61)</option>
                      <option value="Kuwait (+965)">Kuwait (+965)</option>
                      <option value="Kyrgyzstan (+996)">
                        Kyrgyzstan (+996)
                      </option>
                      <option value="Laos (+856)">Laos (+856)</option>
                      <option value="Latvia (+371)">Latvia (+371)</option>
                      <option value="Lebanon (+961)">Lebanon (+961)</option>
                      <option value="Lesotho (+266)">Lesotho (+266)</option>
                      <option value="Liberia (+231)">Liberia (+231)</option>
                      <option value="Libya (+218)">Libya (+218)</option>
                      <option value="Lithuania (+370)">Lithuania (+370)</option>
                      <option value="Luxembourg (+352)">
                        Luxembourg (+352)
                      </option>
                      <option value="Austria (+43)">Austria (+43)</option>
                      <option value="Macao (+853)">Macao (+853)</option>
                      <option value="Macedonia (+389)">Macedonia (+389)</option>
                      <option value="Madagascar (+261)">
                        Madagascar (+261)
                      </option>
                      <option value="Malawi (+265)">Malawi (+265)</option>
                      <option value="Malaysia (+60)">Malaysia (+60)</option>
                      <option value="Maldives (+960)">Maldives (+960)</option>
                      <option value="Mali (+223)">Mali (+223)</option>
                      <option value={44}>44</option>
                      <option value={+91}>+91</option>
                      <option value={49}>49</option>
                      <option value="Belize (+501)">Belize (+501)</option>
                      <option value="Ireland (+353)">Ireland (+353)</option>
                      <option value="Pakistan (+92)">Pakistan (+92)</option>
                      <option value="Ukraine (+380)">Ukraine (+380)</option>
                      <option value="USA (+1)">USA (+1)</option>
                      <option value={595}>595</option>
                      <option value={376}>376</option>
                      <option value={234}>234</option>
                      <option value={256}>256</option>
                      <option value={213}>213</option>
                      <option value={972}>972</option>
                      <option value="United Arab Emirates (+971)">
                        United Arab Emirates (+971)
                      </option>
                      <option value={91}>91</option>
                      <option value={976}>976</option>
                      <option value="Singapore (+65)">Singapore (+65)</option>
                      <option value={33}>33</option>
                      <option value={34}>34</option>
                      <option value="UK (+44)">UK (+44)</option>
                      <option value={39}>39</option>
                      <option value={264}>264</option>
                      <option value={1}>1</option>
                      <option value="Sweden (+46)">Sweden (+46)</option>
                      <option value="Algeria (+213)">Algeria (+213)</option>
                      <option value={886}>886</option>
                      <option value={7}>7</option>
                      <option value={81}>81</option>
                      <option value={966}>966</option>
                      <option value="Germany (+49)">Germany (+49)</option>
                      <option value="Andorra (+376)">Andorra (+376)</option>
                      <option value="Cook Islands (+682)">
                        Cook Islands (+682)
                      </option>
                      <option value="Canada (+1)">Canada (+1)</option>
                      <option value={64}>64</option>
                    </select>
                    <div className="zcwf_col_help" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="Phone">
                      Phone
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="zcwf_col_fld">
                    <input
                      type="text"
                      id="Phone"
                      name="Phone"
                      maxLength={50}
                      errordiv="errorMOBILE"
                      onBlur={phoneValidation}
                      onFocus={() => clearError("errorMOBILE")}
                    />
                    <div className="zcwf_col_help" />
                  </div>
                  <p className="errorMessage" id="errorMOBILE">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="zcwf_row">
                  <div className="zcwf_col_lab">
                    <label htmlFor="Description">Description</label>
                  </div>
                  <div className="zcwf_col_fld">
                    <textarea
                      id="Description"
                      name="Description"
                      defaultValue={""}
                      errordiv="errorDESC"
                      onFocus={() => clearError("errorDESC")}
                    />
                    <div className="zcwf_col_help" />
                  </div>
                  <p className="errorMessage" id="errorDESC">
                    error div
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12} md={6}>
                  <div className="zcwf_row">
                    <div className="zcwf_col_lab">Enter the Captcha</div>
                    <div className="zcwf_col_fld">
                      <input
                        type="text"
                        maxLength={10}
                        name="enterdigest"
                        id="captch_input"
                      />
                    </div>
                  </div>
                </Grid>
                {/* Do not remove this code. */}
                <Grid item xs={12} md={6}>
                  <div className="zcwf_row">
                    <div className="zcwf_col_lab" />
                    <div className="zcwf_col_fld">
                      <img
                        alt="dfsdfsd"
                        id="imgid1832254000001456002"
                        src="https://crm.zoho.com/crm/CaptchaServlet?formId=2c9b6516916ff1bc3f0724e46ac40081695d1f5f9351582b480dc9fb7aad8acddc48f3ba8494407fee4ce9a8237481af&grpid=f7b3c0e0b47b03d6e8e36721448f062fcc1e5e9b90b19af02de72d76fddcf570"
                      />

                      <ReplayIcon
                        className="reloadBtn"
                        onClick={reloadImg1832254000001456002}
                      />
                    </div>
                    <div className="" />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="zcwf_row">
                  <div className="zcwf_privacy">
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div className="dIB vat" align="left">
                        <div className="displayPurpose f13">
                          <label className="newCustomchkbox-md dIB w100per">
                            <input
                              autoComplete="off"
                              id="privacyTool1832254000001456002"
                              type="checkbox"
                              name="privacyTool"
                              onClick={disableErr1832254000001456002}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="dIB zcwf_privacy_txt">
                        I have read the{" "}
                        <a
                          href="https://metapercept.com/privacy-policy/ "
                          title="https://metapercept.com/privacy-policy/ "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>{" "}
                        and I accept the
                        <a
                          href="https://metapercept.com/gdpr/termsandconditions/ "
                          title="https://metapercept.com/gdpr/termsandconditions/ "
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          terms and conditions
                        </a>
                        .
                      </div>
                    </div>
                    <div
                      id="privacyErr1832254000001456002"
                      style={{
                        fontSize: 12,
                        color: "red",
                        paddingLeft: 5,
                        visibility: "hidden",
                      }}
                    >
                      Please accept this
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <div className="zcwf_row">
                  <div className="zcwf_col_lab" />
                  <div className="zcwf_col_fld">
                    <input
                      type="submit"
                      id="formsubmit"
                      className="formsubmit zcwf_button"
                      defaultValue="Submit"
                      title="Submit"
                    />
                    <input
                      type="reset"
                      className="zcwf_button"
                      name="reset"
                      defaultValue="Reset"
                      title="Reset"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
          {/* Do not remove this code. */}
          <iframe
            name="captchaFrame"
            title="Captcha verification frame"
            style={{ display: "none" }}
          />
        </div>
      </Box>
    </>
  );
};

export default ZohoForm;
