import React, { useState } from "react";
import CustomContainer from "../components/CustomContainer";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TryNowBanner from "../components/TryNowBanner";
import sendEmail from "../components/SendEmail";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const smtp_sendTo = process.env.REACT_APP_SMTP_SEND_EMAIL_TO;
const smtp_sendTo2 = process.env.REACT_APP_SMTP_SEND_EMAIL_TO2;
let companyEmailRegex =
  /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.(com|in)|outlook\.com|hotmail\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const TryNowMigration = () => {
  const theme = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL_PREFLIGHT;
  const [formData, setFormData] = useState({
    organization: "",
    jobTitle: "",
    firstName: "",
    lastName: "",
    email: "",
    sourceContent: null,
    sourceType: "",
    isPolicyChecked: false,
    isConversionPolicyChecked: false,
  });

  const [errors, setErrors] = useState({
    organization: "",
    jobTitle: "",
    firstName: "",
    lastName: "",
    email: "",
    sourceContent: "",
    sourceType: "",
    isPolicyChecked: false,
    isConversionPolicyChecked: false,
  });

  const [limitError, setLimitError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openMigrationAlert, setOpenMigrationAlert] = React.useState(false);
  const [fileStatus, setFileStatus] = useState("");
  const [fileStatusError, setFileStatusError] = useState("");
  const [fileStatusErrorMdHtml, setFileStatusErrorMdHtml] = useState("");
  const [fileStatusErrorList, setFileStatusErrorList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fileIsValid, setFileIsValid] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMigrationErrorAlertOpen = () => {
    setOpenMigrationAlert(true);
  };
  const handleMigrationErrorAlertClose = () => {
    setOpenMigrationAlert(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "isPolicyChecked" || name === "isConversionPolicyChecked") {
      setFormData({
        ...formData,
        [name]: e.target.checked,
      });
      setErrors({
        ...errors,
        [name]: e.target.checked ? "" : errors[name],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      let error = "";
      if (value.trim() === "") {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      } else if (name === "email" && !companyEmailRegex.test(value.trim())) {
        error = "Please enter a valid company email.";
      }

      setErrors({
        ...errors,
        [name]: error,
      });
    }
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    let error = "";
    let limitError = "";
    setFileStatusError("");
    setFileStatus("");
    setFileStatusErrorMdHtml("");

    let API_URL = "";

    setFormData({
      ...formData,
      sourceContent: file,
    });

    if (!file) {
      error = "Source content file is required";
      setErrors((prevErrors) => ({
        ...prevErrors,
        sourceContent: error,
      }));
      return;
    }

    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1];

    if (!formData.sourceType === "docxToDita") {
      if (file.size > MAX_FILE_SIZE) {
        limitError = "File size should be less than 5MB";
        setLimitError(limitError);
        return;
      }
      setLimitError("");
    }

    if (formData.sourceType === "docxToDita") {
      API_URL = `${apiUrl}/api/preflight-docx`;
    } else if (formData.sourceType === "htmlToDita") {
      API_URL = `${apiUrl}/api/preflight-html`;
    } else if (formData.sourceType === "mdToDita") {
      API_URL = `${apiUrl}/api/preflight-md`;
    }

    setLoader(true);

    const formDataFile = new FormData();
    formDataFile.append("file", file);

    const response = await fetch(API_URL, {
      method: "POST",
      body: formDataFile,
    });

    const responseData = await response.json();

    if (responseData.status === 200) {
      setFileStatus("Input file is valid");
      setFileIsValid(true);
      setLoader(false);

      setErrors({
        sourceContent: null,
      });
    } else {
      setLoader(false);
      setFileIsValid(false);
      if (fileExtension === "docx") {
        setFileStatus("");
        setFileStatusError(responseData.message || "Input file is not valid.");
      } else {
        setFileStatus("");
        setFileStatusErrorMdHtml(
          responseData.message || "Input file is not valid."
        );
        setFileStatusErrorList(responseData?.invalidFiles);
        handleMigrationErrorAlertOpen();
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = { ...errors };

    if (!formData.isPolicyChecked) {
      newErrors.isPolicyChecked =
        "Please accept the terms of use and privacy policy.";
    } else {
      newErrors.isPolicyChecked = "";
    }

    if (!formData.isConversionPolicyChecked) {
      newErrors.isConversionPolicyChecked =
        "Please read the document conversion policy before uploading the files.";
    } else {
      newErrors.isConversionPolicyChecked = "";
    }

    // Basic validation checks
    if (!formData.organization.trim()) {
      newErrors.organization = "Organization is required.";
    } else {
      newErrors.organization = "";
    }

    if (!formData.jobTitle.trim()) {
      newErrors.jobTitle = "Job title is required.";
    } else {
      newErrors.jobTitle = "";
    }

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    } else {
      newErrors.firstName = "";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    } else {
      newErrors.lastName = "";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!companyEmailRegex.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid company email.";
    } else {
      newErrors.email = "";
    }

    if (!formData.sourceContent) {
      newErrors.sourceContent = "Source content file is required.";
    } else {
      newErrors.sourceContent = "";
    }

    if (!formData.sourceType) {
      newErrors.sourceType = "Source type is required.";
    } else {
      newErrors.sourceType = "";
    }

    if (!fileIsValid) {
      newErrors.sourceContent = "Please upload valid source input file.";
    } else {
      newErrors.sourceContent = "";
    }

    // Set errors state
    setErrors(newErrors);

    // If there are no errors, proceed with form submission logic
    if (Object.values(newErrors).every((error) => error === "")) {
      // Handle form submission logic
      setSubmitLoader(true);
      try {
        const base64String = await getBase64(formData.sourceContent);

        await sendEmail({
          mailTo: `${smtp_sendTo}, ${smtp_sendTo2}`,
          subject: "metR DocMigration (POC) Request",
          mailBody: `
        Hello metR admin, <br/>
  
        <p>Find the below customer details.</p> 
  
        <b>Organization Name:</b> ${formData.organization}<br/>
        <b>Job title:</b> ${formData.jobTitle}<br/>
        <b>Name:</b> ${formData.firstName} ${formData.lastName}<br/>
        <b>Email:</b> ${formData.email}<br/>
        <b>Selected Type:</b>${formData.sourceType}<br/>
        <b>Source Content:</b> ${formData.sourceContent.name}<br/>
        <br/>
        <br/>
        Best Regards <br/>
        metR 
        `,
          attachment: JSON.stringify({
            name: formData.sourceContent.name,
            base64: base64String,
          }),
        });

        handleClickOpen();
        setSubmitLoader(false);
        resetForm();
      } catch (error) {
        console.error("Error reading file", error);
      }
    }
  };

  const getAcceptType = () => {
    if (
      formData.sourceType === "mdToDita" ||
      formData.sourceType === "htmlToDita"
    ) {
      return ".zip";
    } else {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
  };
  const resetForm = () => {
    setFormData({
      organization: "",
      jobTitle: "",
      firstName: "",
      lastName: "",
      email: "",
      sourceContent: null,
      sourceType: "",
      isPolicyChecked: false,
      isConversionPolicyChecked: false,
    });
    setFileStatus("");
    setFileIsValid(false);
    setErrors({});
  };
  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <TryNowBanner title="DocMigration" />
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ maxWidth: 600, mx: "auto", marginBottom: "2rem" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "1rem",
              }}
            >
              Personal Info
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Organization"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  error={Boolean(errors.organization)}
                  helperText={errors.organization}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Job Title"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  error={Boolean(errors.jobTitle)}
                  helperText={errors.jobTitle}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginTop: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Source Content
            </Typography>
            <Grid item xs={12}>
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <InputLabel
                    error={Boolean(errors.sourceType)}
                    id="demo-simple-select-label"
                    style={{
                      background: "var(--white)",
                      padding: "0 5px",
                    }}
                  >
                    Select input source type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="sourceType"
                    name="sourceType"
                    value={formData.sourceType}
                    onChange={handleChange}
                    error={Boolean(errors.sourceType)}
                  >
                    <MenuItem value={"docxToDita"}>Docx </MenuItem>
                    <MenuItem value={"mdToDita"}>Md or Mdx</MenuItem>
                    <MenuItem value={"htmlToDita"}>HTML5</MenuItem>
                  </Select>
                  <FormHelperText error={Boolean(errors.sourceType)}>
                    {errors.sourceType}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  key={formData.sourceType}
                  fullWidth
                  name="file"
                  type="file"
                  onChange={handleFileChange}
                  error={Boolean(errors.sourceContent)}
                  helperText={errors.sourceContent}
                  inputProps={{ accept: getAcceptType() }}
                  disabled={formData.sourceType !== "" ? false : true}
                />

                {loader && (
                  <Box sx={{ width: "100%", marginTop: "4px" }}>
                    <LinearProgress sx={{ borderRadius: "3px" }} />
                  </Box>
                )}

                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "3px",
                    marginLeft: "14px",
                    color: "#000",
                  }}
                >
                  {`Accepted file types: ${
                    formData.sourceType === "docxToDita" ? ".docx" : ".zip"
                  }`}
                </Typography>

                <FormHelperText
                  sx={{ marginLeft: "14px" }}
                  error={Boolean(limitError)}
                >
                  {limitError}
                </FormHelperText>

                {fileStatus && (
                  <FormHelperText
                    sx={{
                      marginLeft: "14px",
                      color: "#00a700",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {fileStatus}
                    <CheckCircleIcon
                      sx={{
                        width: "15px",
                        marginLeft: "3px",
                        marginBottom: "2px",
                      }}
                    />
                  </FormHelperText>
                )}

                {fileStatusError && (
                  <FormHelperText
                    sx={{
                      marginLeft: "14px",
                      color: "#d32f2f",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {fileStatusError}
                    <CancelIcon
                      sx={{
                        width: "15px",
                        marginLeft: "3px",
                        marginBottom: "2px",
                      }}
                    />
                  </FormHelperText>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      marginRight: "0px",
                    }}
                    control={
                      <Checkbox
                        name="isConversionPolicyChecked"
                        checked={formData.isConversionPolicyChecked}
                        onChange={handleChange}
                      />
                    }
                  />
                  <Typography sx={{ fontSize: "14px" }}>
                    Yes, I have read the{" "}
                    <Link
                      to="/resources/conversion-policy"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        color: "var(--text2)",
                      }}
                    >
                      document conversion policy
                    </Link>{" "}
                    before uploading the files.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "-10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      marginRight: "0px",
                    }}
                    control={
                      <Checkbox
                        name="isPolicyChecked"
                        checked={formData.isPolicyChecked}
                        onChange={handleChange}
                      />
                    }
                  />
                  <Typography sx={{ fontSize: "14px" }}>
                    Yes, I accept the{" "}
                    <a
                      href="https://metapercept.com/gdpr/termsandconditions/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        color: "var(--text2)",
                      }}
                    >
                      terms of use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://metapercept.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{
                        color: "var(--text2)",
                      }}
                    >
                      privacy policy.
                    </a>
                  </Typography>
                </Box>

                <FormHelperText
                  error={Boolean(errors.isConversionPolicyChecked)}
                >
                  {errors.isConversionPolicyChecked}
                </FormHelperText>
                <FormHelperText error={Boolean(errors.isPolicyChecked)}>
                  {errors.isPolicyChecked}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} mt={4} sx={{ textAlign: "center" }}>
                {submitLoader ? (
                  <CircularProgress
                    sx={{
                      maxWidth: "25px",
                      maxHeight: "25px",
                    }}
                  />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      minWidth: "120px",
                      background: "var(--secondary)",
                      color: "var(--white)",
                      display: "inline-block",
                      borderRadius: "100px",
                      fontSize: "16px",
                      fontWeight: "400",

                      "&:hover": {
                        backgroundColor: "var(--button-hover)",
                      },
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Thanks for submitting your request. Our sales team will contact
              you soon.
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openMigrationAlert}
            onClose={handleMigrationErrorAlertClose}
            aria-labelledby="migrationErrorAlert"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="migrationErrorAlert">
              {fileStatusErrorMdHtml}
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: "0" }}>
              {fileStatusErrorList && (
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {fileStatusErrorList.map((errorList, index) => (
                    <span
                      style={{
                        display: "block",
                        color: "red",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                      key={index}
                    >
                      {errorList}
                    </span>
                  ))}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMigrationErrorAlertClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </CustomContainer>
    </Box>
  );
};

export default TryNowMigration;
